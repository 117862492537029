<template>
	<div id="home-wallet">
		<div class="content-container">
			<div class="content-inner">
				<div class="container h-100">
					<div class="row flex-sm-row align-items-center h-100">
						<div class="col-md-12 col-lg-6 col-12 col-sm-12">
							<div class="title headline-l">
								{{ $t('homeScreen.wallet.title') }}
							</div>
							<div class="text text-l">
								{{ $t('homeScreen.wallet.description') }}
							</div>
							<div class="text open-wallet text-l">
								{{ $t('homeScreen.general.openWalletPrompt') }}
							</div>
							<div
								class="stores d-flex justify-content-center justify-content-lg-start"
							>
								<store-button :version="'apple'" />
								<store-button :version="'google'" />
							</div>
						</div>
					</div>
				</div>
				<div class="wallet-image-wrapper container">
					<img
						class="wallet-image d-md-block"
						src="/images/elements/home/web/home_phone.png"
						alt="home_phone"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HomeWallet',
	props: {},
	components: {},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {
		handleButtonClick() {
			this.$router.push('/wallet');
		},
	},
};
</script>

<style lang="scss" scoped>
#home-wallet {
	min-height: 594px;
	display: flex;
	border-bottom: 1px solid #4b2eaa80;
	background-image: url('/images/elements/home/web/wallet_background.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@media (max-width: $breakpoint-xs) {
		background-position: 60%;
	}
	.container {
		@media (min-width: $breakpoint-lg) {
			max-width: 1196px;
		}
		.title {
			color: $black;
			margin-bottom: $margin-m;
			text-transform: uppercase;
			@media (max-width: $breakpoint-md) {
				text-align: center;
			}
			@media (min-width: $breakpoint-lg) {
				max-width: 540px;
			}
		}
		.main-image {
			margin-bottom: $margin-s;
		}
		.more-button {
			margin-bottom: $margin-m;
		}
		.text {
			margin-bottom: 20px;
			@media (max-width: $breakpoint-md) {
				text-align: center;
			}
			@media (min-width: $breakpoint-lg) {
				max-width: 540px;
			}
		}
		.open-wallet {
			font-family: 'HKNova-SemiBold', sans-serif;
			margin-top: $margin-m;
		}
		.stores {
			display: flex;
			align-items: center;

			.store-button {
				&:first-child {
					margin-right: 20px;
				}
			}
		}
	}
	.wallet-image-wrapper {
		position: absolute;
		min-height: 594px;
		justify-content: end;
		display: flex;
		margin-top: auto;
		@media (max-width: $breakpoint-md) {
			position: relative;
			justify-content: center;
			min-height: 381px;
		}
		@media (max-width: $breakpoint-md) {
			padding-top: $margin-m !important;
		}
	}
	.wallet-image {
		max-height: 650px;
		object-fit: contain;
		margin-top: auto;
		@media (max-width: $breakpoint-md) {
			height: 100%;
			max-height: 381px;
		}
	}
	.content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		min-height: 594px;
		background: linear-gradient(270deg, #4b2eaa80, transparent 25%),
			rgba(232, 232, 255, 0.85);
		@media (max-width: $breakpoint-sm) {
			min-height: unset;
			height: 100%;
		}
		.content-inner {
			width: 100%;
			display: flex;
			height: 100%;
			flex-direction: column;
			align-items: center;
			z-index: 1;
			@media (max-width: $breakpoint-md) {
				padding-top: $margin-m !important;
			}
		}
	}
}
@media (max-width: $breakpoint-sm) {
	#home-wallet {
		min-height: unset !important;
		.title {
			margin-bottom: $margin-s !important;
		}
		.open-wallet {
			text-align: center;
		}
	}
}
</style>
