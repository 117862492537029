<template>
	<div id="home-services">
		<div class="container">
			<div class="row justify-content-center">
				<div class="title headline-l">
					{{ $t('homeScreen.services.title') }}
				</div>
				<div class="service-grid">
					<div
						class="grid-item"
						v-for="(service, index) in serviceList"
						:key="index"
					>
						<div class="service-wrapper">
							<img :src="service.icon" alt="" />
							<div class="text">{{ service.text }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
  
  <script>
export default {
	name: 'HomeServices',
	props: {},
	components: {},
	data: () => ({}),
	created() {},
	computed: {
		serviceList() {
			return [
				{
					icon: '/images/icons/comfort-blue.svg',
					text: this.$t('homeScreen.services.consulting'),
				},
				{
					icon: '/images/icons/diversity-blue.svg',
					text: this.$t('homeScreen.services.structuring'),
				},
				{
					icon: '/images/icons/it-blue.svg',
					text: this.$t('homeScreen.services.it'),
				},
				{
					icon: '/images/icons/wallet-blue.svg',
					text: this.$t('homeScreen.services.walletServices'),
				},
				{
					icon: '/images/icons/present-blue.svg',
					text: this.$t('homeScreen.services.management'),
				},
				{
					icon: '/images/icons/license.svg',
					text: this.$t('homeScreen.services.compliance'),
				},
			];
		},
	},
	methods: {},
};
</script>
  
  <style lang="scss" scoped>
#home-services {
	position: relative;
	padding-top: $margin-m;
	padding-bottom: 80px;
	padding-left: 15px;
	padding-right: 15px;
	border-bottom: 1px solid #6464ff;
	.container {
		@media (min-width: $breakpoint-lg) {
			max-width: 1196px;
		}
		@media (max-width: $breakpoint-xs) {
			max-width: 100%;
		}
	}
	.title {
		margin-bottom: $margin-m;
		text-transform: uppercase;
		color: $black;
		@media (max-width: 360px) {
			font-size: 28px;
			line-height: 34px;
		}
	}
	.description {
		padding-bottom: 20px;
	}
	.service-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 30px;
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		.service-wrapper {
			background: linear-gradient(60deg, $black 60%, #0f0f9b);
			width: 100%;
			height: 175px;
			display: flex;
			padding-top: 40px;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			padding-left: 10px;
			padding-right: 10px;
			.text {
				color: $white;
				text-align: center;
			}
		}
	}
}
@media (max-width: $breakpoint-sm) {
	#home-services {
		padding-bottom: $margin-m !important;
	}
	.container {
		// max-width: 100%;
		padding: 0;
	}
	.service-grid {
		display: grid;
		grid-template-columns: 1fr 1fr !important;
		gap: 8px !important;
	}
}
</style>
  