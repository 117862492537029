<template>
	<div id="home-block-stock-joinplatform">
		<div class="content-container">
			<div class="content-inner">
				<div class="container">
					<div class="content-section">
						<div class="title headline-l">
							{{ this.$t('homeScreen.joinPlatform.title') }}
						</div>
						<div class="subscription">
							{{ this.$t('homeScreen.joinPlatform.subscription') }}
						</div>
						<base-button
							class="join-button"
							:text="$t('homeScreen.joinPlatform.button')"
							size="large"
							type="light-green"
							@clicked="goToJoin()"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HomeBlockStockDiagram from './HomeBlockStockDiagram';

export default {
	name: 'HomeBlockStock',
	props: {},
	components: {
		HomeBlockStockDiagram,
	},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {
		goToJoin() {
			this.$router.push('/joinplatform');
		},
	},
};
</script>

<style lang="scss" scoped>
#home-block-stock-joinplatform {
	height: 580px;
	width: 100%;
	background: url('/images/elements/home/web/join_background.png') no-repeat
		center center;
	background-size: cover;
	@media (max-width: $breakpoint-xs) {
		height: fit-content;
		background: url('/images/screens/joinplatform/joinplatform_box_bg.png')
			no-repeat center center;
	}
	.container {
		@media (min-width: $breakpoint-lg) {
			max-width: 1196px;
		}
	}
	.content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		@media (max-width: $breakpoint-xs) {
			background: linear-gradient(to top, rgba(10, 8, 84, 0.7), #050533 65%);
		}
		.content-inner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.content-section {
				.title {
					max-width: 720px;
					margin-bottom: 40px;
					letter-spacing: 0em;
					text-align: left;
					color: white;
					text-transform: uppercase;
					@media (max-width: $breakpoint-xs) {
						text-align: center;
						max-width: none;
					}
				}
				.subscription {
					max-width: 720px;
					margin-bottom: 40px;
					font-size: 16px;
					font-weight: 400;
					line-height: 30px;
					letter-spacing: 0em;
					text-align: left;
					color: white;
					@media (max-width: $breakpoint-xs) {
						text-align: center;
						max-width: none;
					}
				}
				.join-button {
					@media (max-width: $breakpoint-xs) {
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: 600px) {
	#home-block-stock-joinplatform {
		.content-container {
			padding-top: 30px;
			padding-bottom: 100px;
		}
	}
}
</style>
