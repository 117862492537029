<template>
	<div id="home-products" class="products">
		<div class="container">
			<div class="inner-container">
				<div class="title headline-l">
					{{ this.$t('homeScreen.products.title') }}
				</div>
				<div class="row justify-content-center" style="flex-wrap: nowrap">
					<div @click="triggerClickPrev" class="nav-button">
						<img src="/images/icons/arrow-left-blue.svg" alt="prev" />
					</div>
					<carousel
						:responsive="owlConfig"
						style="display: flex; width: 100%; max-width: 80%"
						ref="carouselRef"
						@translated="onTranslated"
					>
						<template v-for="(item, index) in products">
							<div
								class="item"
								:key="`carousel-item-${index}`"
								:data-key="item.key"
							>
								<div
									class="text description"
									v-html="item.primaryDescription"
								/>
								<img class="item-image" :src="item.thumbnail" alt="" />
								<span class="text product-title">
									{{ item.title }}
								</span>
								<span
									class="text product-description"
									v-html="item.secondaryDescription"
								/>
							</div>
						</template>
					</carousel>
					<div class="nav-button" @click="triggerClickNext">
						<img src="/images/icons/arrow-right-blue.svg" alt="next" />
					</div>
				</div>
				<base-button
					class="learn-more-button"
					:text="this.$t('homeScreen.products.buttonText')"
					size="large"
					type="light-green"
					@clicked="goToProduct()"
				/>
			</div>
		</div>
	</div>
</template>
  
<script>
import carousel from 'vue-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

export default {
	props: {},
	components: {
		carousel,
	},
	data: () => ({
		owlConfig: {
			0: {
				items: 1,
				nav: false,
				dots: false,
				// margin: 20,
				// stagePadding: 80,
				// startPosition: 0,
				loop: true,
				autoplay: true,
			},
			575: {
				items: 1,
				nav: false,
				dots: false,
				// margin: 20,
				// stagePadding: 80,
				// startPosition: 0,
				loop: true,
				autoplay: true,
			},
			767: {
				items: 1,
				nav: false,
				dots: false,
				// margin: 20,
				// stagePadding: 80,
				// startPosition: 0,
				loop: true,
				autoplay: true,
				smartSpeed: 400,
				navSpeed: 400,
			},
		},
		activeIndex: 0,
	}),
	created() {},
	computed: {
		products() {
			return [
				{
					key: 'holoverz',
					title: this.$t('homeScreen.products.holoverz.title'),
					primaryDescription: this.$t(
						'homeScreen.products.holoverz.primaryDescription',
					),
					secondaryDescription: this.$t(
						'homeScreen.products.holoverz.secondaryDescription',
					),
					link: '/products/holoverz',
					thumbnail: '/images/logos/holoverz_icon_logo.svg',
				},
				{
					key: 'ebso',
					title: this.$t('homeScreen.products.ebso.title'),
					primaryDescription: this.$t(
						'homeScreen.products.ebso.primaryDescription',
					),
					secondaryDescription: this.$t(
						'homeScreen.products.ebso.secondaryDescription',
					),
					link: '/products/ebso',
					thumbnail: '/images/logos/ebso_icon_logo.svg',
				},
				{
					key: 'bno',
					title: this.$t('homeScreen.products.bno.title'),
					primaryDescription: this.$t(
						'homeScreen.products.bno.primaryDescription',
					),
					secondaryDescription: this.$t(
						'homeScreen.products.bno.secondaryDescription',
					),
					link: '/products/bno',
					thumbnail: '/images/logos/bno_icon_logo.svg',
				},
				{
					key: 'bnox',
					title: this.$t('homeScreen.products.bnox.title'),
					primaryDescription: this.$t(
						'homeScreen.products.bnox.primaryDescription',
					),
					secondaryDescription: this.$t(
						'homeScreen.products.bnox.secondaryDescription',
					),
					link: '/products/bnox',
					thumbnail: '/images/logos/bnox_icon_logo.svg',
				},
			];
		},
	},
	methods: {
		triggerClickPrev() {
			this.$refs.carouselRef.$el.querySelector('.owl-prev').click();
		},
		triggerClickNext() {
			this.$refs.carouselRef.$el.querySelector('.owl-next').click();
		},
		onTranslated(event) {
			const carousel = this.$refs.carouselRef.$el;
			const activeItem = Array.from(
				carousel.querySelectorAll('.owl-item'),
			).find((item) => item.classList.contains('active'));

			if (activeItem) {
				const carouselItem = activeItem.querySelector('[data-key]');

				if (carouselItem) {
					const key = carouselItem.getAttribute('data-key');
					this.activeIndex = this.products.findIndex(
						(product) => product.key === key,
					);
				}
			}
		},
		goToProduct() {
			this.$router.push(this.products[this.activeIndex].link);
		},
	},
	mounted() {},
};
</script>
  
<style lang="scss" scoped>
#home-products {
	.container {
		margin: 55px auto 70px auto;
		text-align: center;
		padding: 0 15px;
		@media (min-width: $breakpoint-lg) {
			max-width: 1196px;
		}
	}
	.title {
		color: $black;
		margin-bottom: 24px;
		text-transform: uppercase;
	}
	.description {
		height: 70px;
		@media (max-width: $breakpoint-xs) {
			height: 90px;
		}
	}
	.nav-button {
		width: 34px;
		display: flex;
		height: 100%;
		margin-top: 111px;
		cursor: pointer;
		& :hover {
			opacity: 0.8;
		}
		@media (max-width: $breakpoint-xs) {
			margin-top: 131px;
		}
	}
	.item {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.item-image {
		width: 117px;
		height: 117px;
		display: flex;
	}
	.product-title {
		margin-top: 40px;
		font-size: 24px !important;
		line-height: 30px !important;
		font-family: 'HKNova-Bold', sans-serif;
		font-weight: 700;
		margin-bottom: $margin-s;
	}
	.product-description {
		padding-left: 8%;
		padding-right: 8%;
		margin-bottom: $margin-m;
		@media (max-width: $breakpoint-xs) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}
</style>
  