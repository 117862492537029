<template>
	<div id="home-compliance">
		<div class="container">
			<div class="row flex-sm-row-reverse compliance-row">
				<div
					class="col-md-6 col-12 position-static d-flex align-items-center justify-content-end natrix-illustration"
				>
					<img
						class="d-none d-md-block natrix-web-illustration"
						src="/images/elements/home/web/compliance.png"
						alt="natrix-illustration"
					/>
					<img
						class="d-block d-md-none natrix-mobile-illustration"
						src="/images/elements/home/mobile/compliance.png"
						alt="natrix-illustration"
					/>
					<div class="title-mobile headline-l">
						{{ $t('homeScreen.compliance.title') }}
					</div>
				</div>
				<div class="col-md-6 col-12 content-col">
					<div class="title headline-l">
						{{ $t('homeScreen.compliance.title') }}
					</div>
					<div class="text description">
						{{ $t('homeScreen.compliance.description') }}
					</div>
					<div
						class="text"
						v-html="$t('homeScreen.compliance.complianceText')"
					/>
					<div class="text" v-html="$t('homeScreen.compliance.planningText')" />
					<div
						class="text"
						v-html="$t('homeScreen.compliance.developmentText')"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
  
  <script>
export default {
	name: 'HomeCompliance',
	props: {},
	components: {},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {},
};
</script>
  
  <style lang="scss" scoped>
#home-compliance {
	position: relative;
	padding-top: $margin-m;
	padding-bottom: $margin-m;
	border-bottom: 1px solid #6464ff;
	.natrix-illustration {
		padding: 0;
		.natrix-web-illustration {
			max-width: 445px;
			height: 445px;
			top: 40px;
			object-fit: contain;
		}
		.natrix-mobile-illustration {
			max-width: 100vw;
			width: 100vw;
			@media (max-width: $breakpoint-xs) {
				height: 315px;
				object-fit: cover;
			}
		}
	}
	.container {
		@media (min-width: $breakpoint-lg) {
			max-width: 1196px;
		}
	}
	.title {
		margin-top: $margin-s;
		margin-bottom: $margin-m;
		text-transform: uppercase;
		color: $black;
	}
	.title-mobile {
		display: none;
	}
	.description {
		padding-bottom: 20px;
	}
	.compliance-row {
		width: 100%;
		margin: 0;
	}
}
@media (max-width: $breakpoint-md) {
	.natrix-web-illustration {
		height: 100% !important;
		width: 100% !important;
		position: static !important;
	}
}
@media (max-width: $breakpoint-sm) {
	#home-compliance {
		padding-top: 0 !important;
		padding-bottom: $margin-m !important;
	}
	.container {
		max-width: 100%;
		padding: 0;
	}
	.content-col {
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;
	}
	.title {
		display: none;
	}
	.title-mobile {
		color: $white;
		display: flex !important;
		margin-top: $margin-s;
		margin-bottom: $margin-m;
		position: absolute;
		padding-left: 15px;
		padding-right: 15px;
		text-transform: uppercase;
		text-align: center;
	}
	.description {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: $margin-m;
	}
	.text {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media (max-width: $breakpoint-xs) {
	.content-col {
		max-width: 100%;
	}
	.title {
		display: none;
	}
	.title-mobile {
		text-align: center;
	}

	.text {
		text-align: center;
	}
}
</style>
  