<template>
	<div id="home-learn-more">
		<div class="natrix-illustration-wrapper">
			<div class="container illustration-container">
				<img
					class="natrix-web-illustration"
					:src="imagePath"
					alt="natrix-illustration"
					:style="{ transform: customTransform }"
				/>
			</div>
		</div>
		<div class="content-container">
			<div class="content-inner">
				<div class="container">
					<div class="content-section">
						<div class="title headline-l" v-html="title"></div>
						<div class="natrix-illustration-mobile-wrapper">
							<img
								class="natrix-mobile-illustration"
								:src="imagePath"
								alt="natrix-mobile-illustration"
							/>
						</div>
						<div class="subscription" v-html="description"></div>
						<base-button
							class="join-button"
							:text="buttonText"
							size="large"
							type="light-green"
							@clicked="handleClick"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HomeBlockStockDiagram from './HomeBlockStockDiagram';

export default {
	name: 'HomeLearnMore',
	props: {
		title: {
			type: String,
		},
		description: {
			type: String,
		},
		imagePath: {
			type: String,
		},
		buttonText: {
			type: String,
		},
		customTransform: {
			type: String,
			default: '',
		},
	},
	components: {
		HomeBlockStockDiagram,
	},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {
		handleClick() {
			this.$emit('buttonClicked');
		},
	},
};
</script>

<style lang="scss" scoped>
#home-learn-more {
	height: 610px;
	width: 100%;
	@media (max-width: $breakpoint-md) {
		height: fit-content;
	}
	.natrix-illustration-mobile-wrapper {
		justify-content: center;
		display: flex;
		margin-bottom: $margin-s;
		@media (min-width: $breakpoint-md) {
			display: none;
		}
	}
	.natrix-illustration-wrapper {
		height: 610px;
		width: 100%;
		position: absolute;
		align-items: center;
		display: flex;
		@media (max-width: $breakpoint-md) {
			display: none;
		}
		.natrix-web-illustration {
			position: absolute;
			z-index: 1;
			right: 0;
			@media (max-width: $breakpoint-xs) {
				position: relative;
			}
		}
	}
	.illustration-container {
		height: 610px;
		display: flex;
		align-items: center;
		position: relative;
	}
	.container {
		@media (min-width: $breakpoint-lg) {
			max-width: 1196px;
		}
	}
	.content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		background: linear-gradient(to left, transparent, #050533 40%);
		@media (max-width: $breakpoint-md) {
			background: rgba(5, 5, 53, 0.6);
		}
		.content-inner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			z-index: 1;
			.content-section {
				.title {
					max-width: 630px;
					margin-bottom: 40px;
					letter-spacing: 0em;
					text-align: left;
					color: white;
					text-transform: uppercase;
					@media (max-width: $breakpoint-md) {
						text-align: center;
						max-width: none;
					}
				}
				.subscription {
					max-width: 630px;
					margin-bottom: 40px;
					font-size: 16px;
					font-weight: 400;
					line-height: 30px;
					letter-spacing: 0em;
					text-align: left;
					color: white;
					@media (max-width: $breakpoint-md) {
						text-align: center;
						max-width: none;
					}
				}
				.join-button {
					@media (max-width: $breakpoint-md) {
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: $breakpoint-md) {
	#home-learn-more {
		.content-container {
			padding-top: $margin-m;
			padding-bottom: 80px;
		}
	}
}
</style>
