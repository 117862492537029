<template>
	<div id="home-screen">
		<home-introduction />
		<home-features />
		<home-compliance />
		<home-services />
		<home-block-joinplatform />
		<home-products />
		<div class="learn-more-background-container">
			<home-learn-more
				style="border-bottom: 1px solid #e8e8ff"
				:title="$t('homeScreen.learnMore.blockben.title')"
				imagePath="/images/elements/home/web/learn-more-illustration.png"
				:buttonText="$t('homeScreen.learnMore.blockben.buttonText')"
				:description="$t('homeScreen.learnMore.blockben.description')"
				@buttonClicked="goToAboutUs"
			/>
			<home-learn-more
				:title="$t('homeScreen.learnMore.natrix.title')"
				imagePath="/images/elements/home/web/natrix-illustration.png"
				:buttonText="$t('homeScreen.learnMore.natrix.buttonText')"
				:description="$t('homeScreen.learnMore.natrix.description')"
				@buttonClicked="goToNatrix"
				:customTransform="'scale(-1, 1)'"
			/>
		</div>
		<home-wallet />
		<home-help />
		<blog style="background-color: rgba(232, 232, 255, 1)" />
	</div>
</template>

<script>
import HomeBlockChain from '@/components/modules/home/HomeBlockChain';
import HomeBlockStock from '@/components/modules/home/HomeBlockStock';
import HomeBlockJoinplatform from '@/components/modules/home/HomeBlockJoinplatform';
import HomeNatrix from '@/components/modules/home/HomeNatrix';
import HomeBlocknote from '@/components/modules/home/HomeBlocknote';
import HomeWallet from '@/components/modules/home/HomeWallet';
import HomeFormerProducts from '@/components/modules/home/HomeFormerProducts';
import HomeCompliance from '../components/modules/home/HomeCompliance.vue';
import HomeServices from '../components/modules/home/HomeServices.vue';
import Blog from '@/components/modules/blog/Blog';
import HomeLearnMore from '../components/modules/home/HomeLearnMore.vue';
import HomeHelp from '../components/modules/home/HomeHelp.vue';
import HomeProducts from '../components/modules/home/HomeProducts.vue';
import HomeIntroduction from '../components/modules/home/HomeIntroduction.vue';
import HomeFeatures from '../components/modules/home/HomeFeatures.vue';

export default {
	name: 'HomeScreen',
	props: {},
	components: {
		HomeBlockChain,
		HomeBlockStock,
		HomeNatrix,
		HomeFormerProducts,
		HomeBlocknote,
		HomeWallet,
		Blog,
		HomeBlockJoinplatform,
		HomeCompliance,
		HomeServices,
		HomeLearnMore,
		HomeHelp,
		HomeProducts,
		HomeIntroduction,
		HomeFeatures,
	},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {
		goToAboutUs() {
			this.$router.push('/about-us');
		},
		goToNatrix() {
			console.log('natrix');
			window.open('https://natrix.io/', '_blank');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_fonts.scss';
@import '@/assets/scss/_colors.scss';

#home-screen {
	position: relative;
	background: #e8e8ff;
	&::v-deep {
		.header-background {
			position: absolute;
			top: 0;
			right: 0;
			.header-web-background {
				height: 635px;
			}
			.header-mobile-background {
				width: 100vw;
			}
		}
		.jumbotron {
			position: relative;
			.col-md-6 {
				position: unset;
			}
			.illustration {
				position: absolute;
				width: 540px;
				height: 540px;
				right: 150px;

				@media (max-width: 1200px) {
					right: 70px !important;
				}

				@media (max-width: $breakpoint-md) {
					bottom: -50px;
					width: 415px !important;
					right: 30px !important;
				}
			}
			.jumbotron-top-image img {
				margin-top: 60px;
				max-height: 370px;

				@media (max-width: $breakpoint-md) {
					margin-top: 0;
				}
			}

			.top-section {
				@media (max-width: $breakpoint-sm) {
					padding: 60px 45px 0 0 !important;
				}
			}
		}

		.left-container {
			max-width: 540px;
		}
		.text {
			font-size: $text-l;
			color: $black;
			line-height: 20px;
		}

		.light-purple-row {
			background-color: $lighter-purple;
		}

		.ebso-gold-backing-row {
			background-color: #f6f4ff;
		}
	}
}
.bb-logo-container {
	max-width: 1126px;
	position: absolute;
	top: 1.6%;
	left: 50%;
	transform: translateX(-50%);
	@media (max-width: 1200px) {
		max-width: 960px;
		top: 1.2%;
	}
	@media (max-width: 992px) {
		max-width: 720px;
		top: 1.2%;
	}
}
.learn-more-background-container {
	position: relative;
	width: 100%;
	height: auto;
	background: url('/images/screens/joinplatform/joinplatform_box_bg.png')
		no-repeat center center;
	background-size: cover;
	background-position: center;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #050533bf;
		z-index: 1;
		pointer-events: none;
	}
	@media (max-width: $breakpoint-xs) {
		background-position: 60%;
	}
}
.header-background-image {
	background: url('/images/elements/home/web/home_background.png') no-repeat
		center center;
}
</style>
