<template>
	<div id="home-help" class="help">
		<div class="container">
			<div class="title headline-l">
				{{ $t('homeScreen.help.title') }}
			</div>
			<base-button
				class="join-button"
				:text="$t('homeScreen.help.buttonText')"
				size="large"
				type="light-green"
				@clicked="handleClick()"
			/>
		</div>
	</div>
</template>
  
<script>
import { mapGetters } from 'vuex';
export default {
	name: 'HomeHelp',
	props: {},
	components: {},
	data: () => ({}),
	created() {},
	computed: {
		...mapGetters({
			selectedLanguage: 'settings/getSelectedLanguage',
		}),
	},
	methods: {
		handleClick() {
			if (this.selectedLanguage == 'hu') {
				window.open(
					'https://data.blockben.com/FAQ/blockben_gyik.pdf',
					'_blank',
				);
			} else {
				window.open('https://data.blockben.com/FAQ/blockben_faq.pdf', '_blank');
			}
		},
	},
};
</script>
  
<style lang="scss" scoped>
#home-help {
	background-color: #e8e8ff;
	padding-top: 55px;
	padding-bottom: 70px;
	border-bottom: 1px solid #4b2eaa80;
	.container {
		max-width: 540px;
		text-align: center;
		padding: 0 15px;
	}
	.title {
		color: $black;
		margin-bottom: 40px;
	}
}
</style>
  