<template>
	<div id="home-introduction">
		<div class="mobile-illustration">
			<div alt="" class="logo" />
		</div>
		<div class="content-container">
			<div class="content-inner">
				<div class="container h-100">
					<div class="row flex-sm-row align-items-center h-100">
						<div class="col-md-7 col-lg-6 col-12 col-sm-12">
							<h1
								class="title headline-l"
								v-html="$t('homeScreen.blockChain.title')"
							></h1>
							<div>
								<span class="platforms-label text-l">{{
									$t('homeScreen.general.platformsLabel')
								}}</span>
								<div class="icons">
									<a href="https://www.facebook.com/blockbenofficial">
										<i class="bb-facebook icon" />
									</a>
									<a href="https://twitter.com/blockben?lang=en">
										<img src="/images/icons/x-white.svg" class="icon" />
									</a>
									<a href="https://www.linkedin.com/company/blockben/">
										<i class="bb-linkedin icon" />
									</a>
									<a
										href="https://www.youtube.com/channel/UC9SB-B1Gf50SVnerTLGIMyA"
									>
										<i class="bb-youtube icon" />
									</a>
									<a href="https://t.me/BlockBen_Official">
										<img src="/images/icons/telegram-white.svg" class="icon" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Homeintroduction',
	props: {},
	components: {},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {},
};
</script>

<style lang="scss" scoped>
#home-introduction {
	min-height: 715px;
	display: flex;
	border-bottom: 1px solid #4b2eaa80;
	background-image: url('/images/elements/home/web/home_background.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	flex-direction: column;
	background-color: $black;
	@media (max-width: $breakpoint-lg) {
		background-position-x: 70%;
	}
	@media (max-width: $breakpoint-sm) {
		background-image: url('/images/elements/home/mobile/home_background.png');
		background-position-x: center;
	}
	.container {
		@media (min-width: $breakpoint-lg) {
			max-width: 1196px;
		}
		.title {
			color: $white;
			margin-bottom: $margin-m;
			text-transform: uppercase;
			@media (max-width: $breakpoint-sm) {
				text-align: center;
				font-size: 28px;
				line-height: 34px;
			}
			@media (max-width: $breakpoint-xs) {
				text-align: center;
				font-size: 28px;
				line-height: 34px;
			}
			@media (min-width: $breakpoint-lg) {
				max-width: 540px;
			}
		}
		.text {
			margin-bottom: 20px;
			@media (max-width: $breakpoint-md) {
				text-align: center;
			}
			@media (min-width: $breakpoint-lg) {
				max-width: 540px;
			}
		}
	}
	.content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		min-height: 715px;
		@media (max-width: $breakpoint-sm) {
			height: 100%;
			background: linear-gradient(
				rgba(4, 4, 40, 1),
				rgba(21, 26, 192, 0),
				rgba(13, 13, 95, 1),
				rgba(4, 4, 40, 1)
			);
		}
		.content-inner {
			width: 100%;
			display: flex;
			height: 100%;
			flex-direction: column;
			align-items: center;
			z-index: 1;
			@media (max-width: $breakpoint-md) {
				margin-top: auto;
				margin-bottom: 80px;
			}
		}
	}
}
@media (max-width: $breakpoint-sm) {
	#home-introduction {
		min-height: unset !important;
		.title {
			margin-bottom: $margin-s !important;
		}
	}
}
.platforms-label {
	color: #00dda9;
	margin: 60px 0 20px;
	display: block;
	font-family: 'HKNova-SemiBold', sans-serif;
	@media (max-width: $breakpoint-sm) {
		text-align: center;
	}
}
.icons {
	margin-top: 26px;
	line-height: $text-m;
	display: flex;
	align-items: center;

	@media (max-width: $breakpoint-sm) {
		width: 100%;
		justify-content: space-around;
	}
}

.icon {
	color: $lighter-purple;
	font-size: 20px;
	margin-right: $margin-m;

	@media (max-width: $breakpoint-md) {
		margin-right: $margin-s;
	}

	@media (max-width: $breakpoint-sm) {
		margin-right: 0px;
	}
}
.mobile-illustration {
	position: absolute;
	width: 100%;
	height: 715px;
	display: flex;
	justify-content: center;
	// background: rgba(5, 5, 51, 0.5);
	@media (min-width: $breakpoint-sm) {
		display: none;
	}
}

.logo {
	display: flex;
	margin-top: 175px;
	width: 100%;
	aspect-ratio: 363 / 51;
	height: fit-content;
	margin-left: 20px;
	margin-right: 20px;
	max-width: 420px;
	background-image: url('/images/elements/home/mobile/blockben_logo_illustration.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
</style>
