<template>
	<div id="home-features">
		<div class="container">
			<div class="row features-row">
				<div
					v-for="(feature, index) in featureList"
					:key="index"
					class="feature-wrapper"
				>
					<img
						:src="feature.icon"
						alt=""
						:style="{ padding: feature.hasIconPadding ? '6px' : '0' }"
					/>
					<span class="text-m feature-text" v-html="feature.text" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HomeFeatures',
	props: {},
	components: {},
	data: () => ({}),
	created() {},
	computed: {
		featureList() {
			return [
				{
					icon: '/images/icons/future-blue.svg',
					text: this.$t('homeScreen.features.future'),
					hasIconPadding: false,
				},
				{
					icon: '/images/icons/license.svg',
					text: this.$t('homeScreen.features.license'),
					hasIconPadding: false,
				},
				{
					icon: '/images/icons/chain.svg',
					text: this.$t('homeScreen.features.audit'),
					hasIconPadding: false,
				},
				{
					icon: '/images/icons/wallet-service.svg',
					text: this.$t('homeScreen.features.walletService'),
					hasIconPadding: true,
				},
				{
					icon: '/images/icons/sustainable-security.svg',
					text: this.$t('homeScreen.features.sustainableSecurity'),
					hasIconPadding: false,
				},
				{
					icon: '/images/icons/registration-blue.svg',
					text: this.$t('homeScreen.features.registration'),
					hasIconPadding: false,
				},
			];
		},
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
#home-features {
	background: $black;
	display: flex;
	@media (max-width: $breakpoint-xs) {
		background-position: 60%;
	}
	.container {
		@media (min-width: $breakpoint-lg) {
			max-width: 1196px;
		}
		.text {
			margin-bottom: 20px;
			@media (max-width: $breakpoint-md) {
				text-align: center;
			}
			@media (min-width: $breakpoint-lg) {
				max-width: 540px;
			}
		}
	}
}
@media (max-width: $breakpoint-sm) {
	#home-introduction {
		min-height: unset !important;
	}
}
.features-row {
	justify-content: space-between;
	padding-bottom: $margin-m;
	@media (min-width: 992px) {
		padding-bottom: 40px;
	}
	@media (max-width: $breakpoint-sm) {
		padding-top: 25px;
	}
	.feature-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding-top: $margin-s;
		gap: 10px;
		max-width: 160px;

		@media (max-width: $breakpoint-xs) {
			flex: 1 1 50%;
			max-width: none;
		}

		@media (min-width: $breakpoint-sm) {
			flex: 1 1 30%;
			max-width: none;
		}

		@media (min-width: $breakpoint-md) {
			flex: 1 1 auto;
			max-width: 160px;
		}
		.feature-text {
			padding-left: 10px;
			padding-right: 10px;
			color: $lighter-purple;
			text-align: center;
		}
	}
}
</style>
